(function() {
  if(!document.querySelector('.flexible-hero-section')){
    return;
  }
  const flexibleHeroOnLoad = function() {
    document.querySelector('.flexible-hero-section').classList.add("loaded");
  }

  const flexibleHeroDOMContentLoaded = () => {
    const titleElement = document.querySelector('.flexible-hero-section .text-container .title');
    const title = titleElement.textContent;

    const words = title.split(' ');
    const wrappedWords = words.map(word => `<span>${word}</span>`);
    titleElement.innerHTML = wrappedWords.join(' ');

    const spans = document.querySelectorAll('.flexible-hero-section .text-container .title span');
    spans.forEach(flexibleHeroCreateSpanElem);
  }

  const flexibleHeroCreateSpanElem = (word, key) => {
    word.className = 'animation-span animation-delay-' + key;
  }

  document.addEventListener('DOMContentLoaded', flexibleHeroDOMContentLoaded);
  window.addEventListener('load', flexibleHeroOnLoad);
})();
