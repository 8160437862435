/* global google */

$(document).on('ready', function () {
  // This function will render each map when the document is ready (page has loaded)
  $('.acf-map').each(function () {
    render_map($(this));
  });
});

/*
 *  This function will render a Google Map onto the selected jQuery element
 */

function render_map($el) {
  // var
  let $markers = $el.find('.marker');
  // let styles = Here should be styles for Google Maps from https://snazzymaps.com/explore ; // Uncomment for map styling

  // vars
  let args = {
    zoom: 16,
    center: new google.maps.LatLng(0, 0),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: false,
    // styles : styles // Uncomment for map styling
  };

  // create map
  let map = new google.maps.Map($el[0], args);

  // add a markers reference
  map.markers = [];

  // add markers
  $markers.each(function () {
    add_marker($(this), map);
  });

  // center map
  center_map(map);
}

/*
 *  This function will add a marker to the selected Google Map
 */

let infowindow;

function add_marker($marker, map) {
  // var
  let latlng = new google.maps.LatLng(
    $marker.attr('data-lat'),
    $marker.attr('data-lng')
  );

  // create marker
  let marker = new google.maps.Marker({
    position: latlng,
    map: map,
    //icon: $marker.data('marker-icon') //uncomment if you use custom marker
  });

  // add to array
  map.markers.push(marker);

  // if marker contains HTML, add it to an infoWindow
  if ($.trim($marker.html())) {
    // create info window
    infowindow = new google.maps.InfoWindow();

    // show info window when marker is clicked
    google.maps.event.addListener(marker, 'click', function () {
      // Close previously opened infowindow, fill with new content and open it
      infowindow.close();
      infowindow.setContent($marker.html());
      infowindow.open(map, marker);
    });
  }
}

/*
 *  This function will center the map, showing all markers attached to this map
 */

function center_map(map) {
  // vars
  let bounds = new google.maps.LatLngBounds();

  // loop through all markers and create bounds
  $.each(map.markers, function (i, marker) {
    let latlng = new google.maps.LatLng(
      marker.position.lat(),
      marker.position.lng()
    );
    bounds.extend(latlng);
  });

  // only 1 marker?
  if (map.markers.length == 1) {
    // set center of map
    map.setCenter(bounds.getCenter());
  } else {
    // fit to bounds
    map.fitBounds(bounds);
  }
}
