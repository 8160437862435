(function() {
  const homepageHeroOnLoad = function() {
    if (document.querySelector('.hero-section')){
      document.querySelector('.hero-section').classList.add('loaded');
    }
  }

  const homepageHeroDOMContentLoaded = () => {
    if (!document.querySelector('.hero-section .text-overlay .text-title')) {
      return;
    }
    const titleElement = document.querySelector('.hero-section .text-overlay .text-title');
    const titleHTML = titleElement.innerHTML.trim();

    const splitTextWithBr = titleHTML.split(/(<br[^>]*>)/g);
    let wrappedText = '';

    splitTextWithBr.forEach(part => {
      if (part.match(/<br[^>]*>/)) {
        wrappedText += part;
      } else {
        const chars = part.split('');
        const wrappedChars = chars.map(char => `<span>${char}</span>`).join('');
        wrappedText += wrappedChars;
      }
    });

    titleElement.innerHTML = wrappedText;

    const spans = document.querySelectorAll('.hero-section .text-overlay .text-title span');
    const animationDuration = 2200;
    const delayMultiplier = animationDuration / 21;
    let totalTitleAnimationTime = 0;

    spans.forEach((span, index) => {
      const delay = index * delayMultiplier;
      span.style.transitionDelay = `${delay}ms`;
      span.style.transitionDuration = `${animationDuration / 3}ms`;
      totalTitleAnimationTime = (delay + (animationDuration / 3)) * 0.8;
    });

    const textBody = document.querySelector('.hero-section .text-overlay .text-body');
    const heroButtonsContainer = document.querySelector('.hero-section .text-overlay .hero-buttons-container');
    const buttonBorder = document.querySelectorAll('.hero-section .text-overlay .hero-buttons-container .hero-button .border-box rect');

    textBody.style.transitionDelay = `${totalTitleAnimationTime}ms`;
    heroButtonsContainer.style.transitionDelay = `${totalTitleAnimationTime * 1.09}ms`;
    buttonBorder.forEach(border => {
      border.style.transitionDelay = `${totalTitleAnimationTime * 1.09}ms`;
    });
  }

  document.addEventListener('DOMContentLoaded', homepageHeroDOMContentLoaded);
  window.addEventListener('load', homepageHeroOnLoad);
})();
