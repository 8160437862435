import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

document.addEventListener('DOMContentLoaded', () => {
  if(!document.querySelector('.usp-section')){
    return;
  }
  const header = document.querySelector('.usp-section .usp-wrapper .usp-header');
  const uspItemsAmount = document.querySelectorAll('.usp-section .usp-wrapper .usp-item').length;

  const titleElement = document.querySelector('.usp-section .usp-wrapper .usp-header .usp-title');
  if (!titleElement) return;

  const titleHTML = titleElement.innerHTML.trim();
  const splitTextWithBr = titleHTML.split(/(<br[^>]*>)/g);
  let wrappedText = '';

  splitTextWithBr.forEach(part => {
    if (part.match(/<br[^>]*>/)) {
      wrappedText += part;
    } else {
      const chars = part.split('');
      const wrappedChars = chars.map(char => `<span>${char}</span>`).join('');
      wrappedText += wrappedChars;
    }
  });

  titleElement.innerHTML = wrappedText;

  const animationDuration = 2200;
  const delayMultiplier = animationDuration / 21;
  let totalTitleAnimationTime = 0;
  const spans = document.querySelectorAll('.usp-section .usp-wrapper .usp-header .usp-title span');

  spans.forEach((span, index) => {
    const delay = index * delayMultiplier;
    span.style.transitionDelay = `${delay}ms`;
    span.style.transitionDuration = `${animationDuration / 3}ms`;
    totalTitleAnimationTime = (delay + (animationDuration / 3)) * 0.8;
  });

  titleElement.style.setProperty('--line-transition-delay', `${totalTitleAnimationTime}ms`);

  gsap.registerPlugin(ScrollTrigger);

  const mm = gsap.matchMedia();

  mm.add({
      isDesktop: '(min-width: 801px)',
      isMobile: '(max-width: 800px)',
    },
    (context) => {
      let { isDesktop, isMobile } = context.conditions;

      const headerAnimationStart = isDesktop ? 20 : isMobile ? 7 : 20;
      gsap.to(header, {
        scrollTrigger: {
          trigger: '.usp-section',
          start: () => `${headerAnimationStart}% bottom`,
          onEnter() {
            header.classList.add('show');
          },
        },
      });

      for (let i = 1; i <= uspItemsAmount; i++) {
        const item = `.usp-section .usp-wrapper .usp-item.usp-item-${i}`;
        gsap.to(item, {
          scrollTrigger: {
            trigger: '.usp-section',
            start: () => `${((100 - (headerAnimationStart + 10)) / uspItemsAmount * i) + headerAnimationStart}% bottom`,
            onEnter() {
              document.querySelector(item).classList.add('show');
            },
          },
        });
      }
    });


});
