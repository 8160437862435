
let currentIndex = 0;
let amountOfTestimonials = 0;

document.addEventListener("DOMContentLoaded", function () {
  const testimonials = document.querySelectorAll(".single");
  const dotsContainer = document.querySelector(".dots");

  // Create dots
  for (let i = 0; i < testimonials.length; i++) {
    const dot = document.createElement('a');
    dot.classList.add('indicator');
    dot.setAttribute('data-index', i);
    dot.onclick = function() {
      showItem(parseInt(this.getAttribute('data-index')));
    };
    dotsContainer.appendChild(dot);
    if (i===0){
      dot.classList.add('active');
    }
  }
});

const prevButton = document.querySelector(".prev-button");
const nextButton = document.querySelector(".next-button");

if(prevButton && nextButton){
  prevButton.addEventListener("click", function() {
    useArrowButton("left");
  });

  nextButton.addEventListener("click", function() {
    useArrowButton("right");
  });
}

function showItem(index) {
  currentIndex = index;
  const items = document.querySelectorAll('.single');
  amountOfTestimonials = items.length;
  const dots = document.querySelectorAll('.indicator');

  for (let i = 0; i < items.length; i++) {
    if (i === index) {
      items[i].classList.add('active');
      dots[i].classList.add('active');
    } else {
      items[i].classList.remove('active');
      dots[i].classList.remove('active');
    }
  }
}

function useArrowButton(direction){
  if (direction === 'left'){
    if (currentIndex!==0){
      showItem(currentIndex-1);
    } else if (currentIndex===0){
      showItem(amountOfTestimonials-1);
    }
  } else if (direction === 'right'){
    if (currentIndex!==amountOfTestimonials-1){
      showItem(currentIndex+1);
    } else if (currentIndex===amountOfTestimonials-1){
      showItem(0);
    }
  }
}
// document.addEventListener('touchstart', handleTouchStart, false);
// document.addEventListener('touchmove', handleTouchMove, false);
//
// let xDown = null;
// let yDown = null;
//
// function getTouches(evt) {
//   return evt.touches ||             // browser API
//     evt.originalEvent.touches; // jQuery
// }
//
// function handleTouchStart(evt) {
//   const firstTouch = getTouches(evt)[0];
//   xDown = firstTouch.clientX;
//   yDown = firstTouch.clientY;
// }
//
// function handleTouchMove(evt) {
//   if ( ! xDown || ! yDown ) {
//     return;
//   }
//
//   const xUp = evt.touches[0].clientX;
//   const yUp = evt.touches[0].clientY;
//
//   const xDiff = xDown - xUp;
//   const yDiff = yDown - yUp;
//
//   if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
//     if ( xDiff > 0 ) {
//       if (currentIndex!==0){
//         showItem(currentIndex-1);
//       } else if (currentIndex===0){
//         showItem(amountOfTestimonials-1);
//       }
//     } else {
//       if (currentIndex!==amountOfTestimonials-1){
//         showItem(currentIndex+1);
//       } else if (currentIndex===amountOfTestimonials-1){
//         showItem(0);
//       }
//     }
//   }
//   /* reset values */
//   xDown = null;
//   yDown = null;
// }
