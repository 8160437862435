import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

document.addEventListener('DOMContentLoaded', () => {
  if(!document.querySelector('.cta2-section')){
    return;
  }
  const front_image = document.querySelector('.cta2-section .cta2-block .image-container .front-image');
  const back_image = document.querySelector('.cta2-section .cta2-block .image-container .back-image');

  gsap.registerPlugin(ScrollTrigger);

  const mm = gsap.matchMedia();

  mm.add({
      isDesktop: '(min-width: 801px)',
      isMobile: '(max-width: 500px)',
      isMobileBig: '(max-width: 800px)',
    },
    (context) => {
      let { isDesktop, isMobileBig, isMobile } = context.conditions;

      const yMovementFront = isDesktop ? 190 : isMobile ? 70 : isMobileBig ? 90 : 70;
      const yMovementBack = isDesktop ? 100 : isMobile ? 30 : 30;


      gsap.to(front_image, {
        scrollTrigger: {
          trigger: isMobileBig ? '.cta2-section .image-container' : '.cta2-section',
          start: `top bottom`,
          endTrigger: '.cta2-section',
          end: 'bottom top',
          scrub: true,
        },
        ease: 'slow(0.7,0.7,false)',
        y: yMovementFront,
      });

      gsap.to(back_image, {
        scrollTrigger: {
          trigger: isMobileBig ? '.cta2-section .image-container' : '.cta2-section',
          start: `top bottom`,
          endTrigger: '.cta2-section',
          end: 'bottom top',
          scrub: true,
        },
        ease: 'slow(0.7,0.7,false)',
        y: yMovementBack,
      });
    });
});
