import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

document.addEventListener('DOMContentLoaded', () => {
  if(!document.querySelector('.cta-block')){
    return;
  }
  const navigation = document.querySelector('.cta-block .cta-block-wrapper .image-navigation .navigation-wrapper');
  const text = document.querySelector('.cta-block .cta-block-wrapper .text-container');

    const titleElement = document.querySelector('.cta-block .cta-block-wrapper .text-container .text-title');
    if (!titleElement) return;

    const titleHTML = titleElement.innerHTML.trim();
    const splitTextWithBr = titleHTML.split(/(<br[^>]*>)/g);
    let wrappedText = '';

    splitTextWithBr.forEach(part => {
      if (part.match(/<br[^>]*>/)) {
        wrappedText += part;
      } else {
        const chars = part.split('');
        const wrappedChars = chars.map(char => `<span>${char}</span>`).join('');
        wrappedText += wrappedChars;
      }
    });

    titleElement.innerHTML = wrappedText;

    const animationDuration = 2200;
    const delayMultiplier = animationDuration / 21;
    let totalTitleAnimationTime = 0;
    const spans = document.querySelectorAll('.cta-block .cta-block-wrapper .text-container .text-title span');

    spans.forEach((span, index) => {
      const delay = index * delayMultiplier;
      span.style.transitionDelay = `${delay}ms`;
      span.style.transitionDuration = `${animationDuration / 3}ms`;
      totalTitleAnimationTime = (delay + (animationDuration / 3)) * 0.8;
    });

    document.querySelector('.cta-block .cta-block-wrapper .text-container').style.setProperty('--title-animation-duration', `${totalTitleAnimationTime}ms`);

  gsap.registerPlugin(ScrollTrigger);

  const mm = gsap.matchMedia();

  mm.add({
      isDesktop: '(min-width: 1201px)',
      isMobile: '(max-width: 1200px)',
      // isMobileBig: '(max-width: 800px)',
    },
    (context) => {
      let { isDesktop } = context.conditions;

      const yMovement = isDesktop ? 70 : 50;


      gsap.to(navigation, {
        scrollTrigger: {
          trigger: '.cta-block .image-navigation',
          start: `top bottom`,
          endTrigger: '.cta-block',
          end: 'bottom top',
          scrub: true,
        },
        ease: 'slow(0.7,0.7,false)',
        y: yMovement,
      });

      gsap.to(text, {
        scrollTrigger: {
          trigger: '.cta-block .text-container',
          start: `top bottom`,
          onEnter() {
            text.classList.add('show');
          },
        },
      });
    });
});
