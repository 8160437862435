const carousel = document.querySelector(".posts");
const sections = [...document.querySelectorAll(".post")];
const prevButton = document.querySelector('#prev-button');
const nextButton = document.querySelector('#next-button');

if(prevButton && nextButton){
  const options = { threshold: 0.4 };

  const itemsCallback = (entries) => {
    entries.forEach((entry) => {
      entry.target.classList.toggle("is-visible", entry.isIntersecting);
    });
  };

  const firstItemCallback = (entries) => {
    entries.forEach((entry) => {
      carousel.classList.toggle("is-start", entry.isIntersecting);
      prevButton.classList.toggle("disabled", entry.isIntersecting);
    });
  };

  const lastItemCallback = (entries) => {
    entries.forEach((entry) => {
      carousel.classList.toggle("is-end", entry.isIntersecting);
      nextButton.classList.toggle("disabled", entry.isIntersecting);
    });
  };

  const itemsObserver = new IntersectionObserver(itemsCallback, options);
  const firstItemsObserver = new IntersectionObserver(firstItemCallback, options);
  const lastItemObserver = new IntersectionObserver(lastItemCallback, options);

  sections.forEach((section) => {
    itemsObserver.observe(section);
  });
  firstItemsObserver.observe(sections[0]);
  lastItemObserver.observe(sections[sections.length - 1]);

  prevButton.addEventListener('click', () => {
    const firstVisibleItem = carousel.querySelector('.is-visible');
    const prevItemIndex = sections.indexOf(firstVisibleItem) - 1;
    const prevItem = sections[prevItemIndex];
    if (prevItem){
      prevItem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  })

  nextButton.addEventListener('click', () => {
    const nextItem = carousel.querySelector('.is-visible+.post:not(.is-visible)');
    if (nextItem){
      nextItem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
    }
  })
}

